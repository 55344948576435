<template>
  <div class="readme-article">
    <h1 id="其他配置">5.其他配置</h1>
    <h2 id="校区设置">5.1校区设置</h2>
    <p>
      根据公司校区情况添加校区。
    </p>
    <p>
      <img src="@/assets/img/student/config5-1.png" />
    </p>
    <h2 id="分公司设置">5.2分公司设置</h2>
    <p>
      根据公司情况添加分公司。
    </p>
    <p>
      <img src="@/assets/img/student/config5-2.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Student0-5",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>